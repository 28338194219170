type WidthHeight = {
  width: number;
  height: number;
};

/**
 * This function can be used to update width and height based preserving aspect ratio
 *
 * @param inputWidthHeight - An object representing a something with a width and height
 * @param targetWidth - The target width to resize the inputWidthHeight object with
 * @returns WidthHeight
 */
export function resizeWidthHeightFromWidth(
  { width, height }: WidthHeight,
  targetWidth: number
): WidthHeight {
  const targetHeight = height * (targetWidth / width);
  return { width: targetWidth, height: targetHeight };
}
