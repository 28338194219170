import React, { useRef, useState } from "react";
import Slider from "react-slick";
import ReactCSSTransitionReplace from "react-css-transition-replace";
import classnames from "classnames";
import Image from "next/legacy/image";
import { Link } from "../Link";
import { getSlugFromURL } from "./utils/get-slug-from-url";
import { getResizedImageURL, resizeWidthHeightFromWidth } from "../utils";
import { FeaturedProject } from "./types/featured-project";
import styles from "./FeaturedProjects.module.scss";

const settings = {
  arrows: false,
  autoplay: false,
  autoplaySpeed: 6000,
  dots: false,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
};

export const FeaturedProjects = ({ data }: any) => {
  const slider = useRef<Slider>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // Get the current slide and project slug from it's provided link
  const currentSlide = data.projects[currentSlideIndex];
  const numSlides = data.projects.length;

  const _settings = {
    ...settings,
    beforeChange: (_: number, nextIndex: number) =>
      setCurrentSlideIndex(nextIndex),
  };

  const wrapperCls = classnames(styles.featuredProjects, {
    [styles.featuredProjectsWhite]: data.widget_background_colour === "white",
    [styles.featuredProjectsGrey]: data.widget_background_colour === "grey",
    [styles.featuredProjectsTransparent]:
      data.widget_background_colour === "transparent",
  });

  const subtitle =
    data.subheading ||
    "Reforestation benefits the earth and local communities.";

  return (
    <section className={wrapperCls}>
      <h3 className={styles.featuredProjectsTitle}>{data.title}</h3>
      <h4 className={styles.featuredProjectsSubtitle}>{subtitle}</h4>
      <div className={styles.featuredProjectsSlides}>
        <Slider ref={slider} {..._settings}>
          {data.projects.map((project: FeaturedProject, index: number) => {
            const imgURL = getResizedImageURL(project.image.url, 1448, 960, {
              crop: true,
            });
            const { width, height } = resizeWidthHeightFromWidth(
              project.image,
              786
            );

            // Get the project slug from the provided link
            const projectSlug = getSlugFromURL(project.link);

            // We need to nest the slide in a wrapping div to preserve
            // the slides own styles
            return (
              <div key={index}>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <Link
                  aria-label="ecologi project"
                  href={`/projects/${projectSlug}`}
                  className={styles.featuredProjectsSlide}
                >
                  <Image
                    width={width}
                    height={height}
                    src={imgURL || ""}
                    alt="Ecologi project"
                  />
                </Link>
              </div>
            );
          })}
        </Slider>
        <div className={styles.featuredProjectsSlideText}>
          <ReactCSSTransitionReplace
            transitionName="cross-fade"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            <h5
              className={styles.featuredProjectsSlideTitle}
              key={currentSlide.title}
            >
              {currentSlide.title}
            </h5>
          </ReactCSSTransitionReplace>
          <ReactCSSTransitionReplace
            transitionName="cross-fade"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            <p
              className={styles.featuredProjectsSlideBody}
              key={currentSlide.title}
            >
              {currentSlide.body}
            </p>
          </ReactCSSTransitionReplace>
          <button
            className={styles.prevButton}
            type="button"
            disabled={currentSlideIndex === 0}
            aria-label="View previous project"
            title="View previous project"
            onClick={(e) => {
              e.currentTarget.blur(); // drop focus
              if (slider && slider.current) {
                slider.current.slickGoTo(currentSlideIndex - 1);
              }
            }}
          />
          <button
            className={styles.nextButton}
            disabled={currentSlideIndex === numSlides - 1}
            type="button"
            aria-label="View next project"
            title="View next project"
            onClick={(e) => {
              e.currentTarget.blur(); // drop focus
              if (slider && slider.current) {
                slider.current.slickGoTo(currentSlideIndex + 1);
              }
            }}
          />
        </div>

        <div className={styles.featuredProjectsSliderDots}>
          {data.projects.map((_: any, index: number) => (
            <button
              aria-label="slider dot"
              role="radio"
              aria-checked={currentSlideIndex === 0}
              key={index}
              className={classnames(styles.featuredProjectsSliderDot, {
                [styles.featuredProjectsSliderDotCurrent]:
                  index === currentSlideIndex,
              })}
              onClick={(e) => {
                e.preventDefault();
                e.currentTarget.blur();
                if (slider && slider.current) {
                  slider.current.slickGoTo(index);
                }
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
